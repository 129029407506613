.topBox{
display: flex;
flex-direction: column;
}
.imageBox{
    width: 800px;
    height: 300px;
    background-color: #FBFBFB;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    flex-basis: 80%;
    border: 1px solid #fbfbfb6f;
}
.chooseFile{
    color: #5683FF;
    cursor: pointer;
}
.input[type="file"] {
    /* cursor: pointer; */
    color: transparent;
    /* border: 1px solid red; */
    /* display: none; */
  }