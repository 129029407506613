@font-face {
  font-family: 'PoppinsLight';
  src        : url('./Assets/Fonts/Poppins-Light.ttf');
}

@font-face {
  font-family: 'PoppinsSemiBold';
  src        : url('./Assets/Fonts/Poppins-SemiBold.ttf');
}

@font-face {
  font-family: 'PoppinsMedium';
  src        : url('./Assets/Fonts/Poppins-Medium.ttf');
}

@font-face {
  font-family: 'PoppinsRegular';
  src        : url('./Assets/Fonts/Poppins-Regular.ttf');
}


.root {
  height: 100vh;
}

body {
  margin     : 0;
  font-family: 'Poppins', sans-serif;
  /* -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', */

  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-background-color {
  background-color: #F6F6F6;
  width           : 100vw;
  height          : 100vh;
}

.medium-font {
  font-family: 'PoppinsMedium'
}

.side-bar-logo {
  padding: 20px 40px 44px;
  height : 96px;
}

.drawer-logo {
  margin-left: 12px;
  width      : 206px !important;
}

.medium-text {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500 !important;
  font-size  : 16px !important;
}

.drawer-icons {
  width       : 18px;
  height      : 18px;
  margin-right: 15px;
}

.search-placeHolder {
  color         : #9D9D9D !important;
  font-size     : 13px !important;
  font-family   : 'PoppinsLight' !important;
  /* font-family: 'Poppins'; 
 font-weight    : lighter; */
}

.space-between {
  display        : flex;
  justify-content: space-between;
  width          : 100%;
}

.circular-image-container {
  width        : 20px;
  height       : 20px;
  overflow     : hidden;
  border-radius: 50%;
}

.circular-image {
  width : 100%;
  /* Ensures the image takes the full size of its container */
  height: auto;
  /* Maintains aspect ratio */
  margin: 10px;
}

button.header-logout {
  background   : #FF7D73;
  color        : white;
  border-radius: 10px;
  font-size    : 12px;
  border       : none;
  min-width    : 120px;
  height       : 45px;
}

/* .selected-dashboard {
  border-left-style: solid;
  border-left-color: #FF7d73;
} */

.selected-menu {
  color     : #FF7d73 !important;
  background: #F6F6F6 !important;
  opacity   : 1 !important;
  font-size : 16px;
}

.non-selected-menu {
  color        : #6A6A6A !important;
  /* background:#F6F6F6 !important; */
  opacity      : 1 !important;
  font-size    : 16px;
}

.btn-danger {
  background-color: #FF7D73 !important;
  color           : white !important;
  border          : none !important;
  padding         : 5px 12px 5px 12px !important;
  font-size       : 15px !important;
  border-radius   : 5px !important;
  font-family     : 'PoppinsSemiBold' !important;
  text-transform  : capitalize !important;

}

.btn-outline-danger {
  background-color: white !important;
  color           : #FF7D73 !important;
  border          : 1px solid #FF7D73 !important;
  font-size       : 12px !important;
  border-radius   : 8px !important;
  font-family     : 'PoppinsSemiBold' !important;
  background      : rgb(255, 125, 115);
  border          : transparent;
  font-size       : 12px;
  padding         : 6px 10px;
  color           : rgb(255, 255, 255);
  border-radius   : 8px !important;
}

.btn-white {
  background-color: white !important;
  color           : gray !important;
  border          : 1px solid gray !important;
  padding         : 5px 12px 5px 12px !important;
  font-size       : 15px !important;
  border-radius   : 5px !important;
  font-family     : Poppins !important;
  text-transform  : capitalize !important;
  border          : none !important
}

.poppins-family {
  font-family: Poppins !important;
}

th {
  font-weight: bold !important;
}

th,
td {
  font-family: Poppins !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-23 {
  font-size: 23px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.text-danger {
  color: #FF7D73 !important;
}

.text-gray {
  color: gray !important;
}

.poppins-light-font {
  font-family: 'PoppinsLight' !important;
}

.psemiboldfont {
  font-family: 'PoppinsSemiBold' !important;
}

/* .unauthorized-background-image {
  background-image: url("./Assets/images/auth-background-image.png");
  filter: blur(18px);
  -webkit-filter: blur(18px);
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
} */

.unauth-section {
  width  : 100%;
  height : 100vh;
  display: flex;
}

.unauth-section:before {
  content            : '';
  background-image   : url("./Assets/images/auth-background-image.png");
  background-repeat  : no-repeat;
  width              : 100%;
  height             : 100vh;
  filter             : blur(19px);
  position           : absolute;
  left               : 0;
  top                : 0;
  background-position: center;
  background-size    : 100%;
  z-index            : -1;
}

.unauth-box {
  max-width    : 491px;
  padding      : 35px;
  margin       : auto;
  border-radius: 12px;
  background   : #fff;
}

.login .logo {
  max-width: 206px;
}




/* .unauth-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
} */

.drawer-text {
  color: #6A6A6A;
}

.tab-menu {
  font-family   : 'PoppinsMedium' !important;
  color         : #808080 !important;
  text-transform: none !important;
  font-size     : 14px !important;
}

.table-text-color {
  color      : #2C2C2C !important;
  font-size  : 14px !important;
  font-family: 'PoppinsRegular' !important;
}

.text-gray {
  color: #2C2C2C !important;
}

.dropDown {
  color: #398cdf;
}

.active-text {
  color: #24BC94 !important;
}

.modal {
  position        : absolute !important;
  top             : 50% !important;
  left            : 50% !important;
  transform       : translate(-50%, -50%) !important;
  width           : 400 !important;
  background-color: white !important;
  border          : 2px solid #000 !important;
  box-shadow      : 24 !important;
  padding         : 4 !important;
}

/* .default-page-height {
  height    : 90vh;
  overflow-y: auto !important;
} */

.MuiTableContainer-root,
.ant-table-container {
  height  : calc(100vh - 440px);
  overflow: auto;
}

.page-header-text {
  color      : #2C2C2C;
  margin-left: 10px !important;
  font-size  : 20px !important;
}

.page-header-icon {
  width : 20px;
  height: 20px;
}

.subtitle-color {
  color: #808080;
}

.horizontal-card {
  display  : flex;
  flex-wrap: nowrap;
  gap      : 20px;
  /* Adjust the gap between cards */
}

.card {
  /* width: 200px;  */
  border        : none !important;
  border-radius : 8px;
  flex-direction: row !important;
  overflow      : hidden;
  display       : flex !important;
}

.card img {
  width : 100%;
  height: auto;
}

.card-content {
  padding: 10px;
}

.card-content h2 {
  margin   : 0;
  font-size: 18px;
}

.card-content p {
  margin   : 0;
  font-size: 14px;
}

.row-view {
  display       : flex;
  flex-direction: row;
}

.icon-primary-color {
  color      : #FF7D73;
  height     : 20px !important;
  width      : 20px !important;
  margin-left: -5px;
}

.mt {
  margin-top: 11px !important;
}

.mb {
  margin-bottom: 11px !important;
}

/*Toufiq common css*/
@import url('https://fonts.cdnfonts.com/css/segoe-ui-4');

h1,
h2,
h3,
h4,
h5,
h6,
body,
label,
input,
li,
p,
span {
  font-family: Poppins !important;
}

.card-title h5 {
  color        : #2C2C2C !important;
  font-size    : 24px !important;
  line-height  : 32px !important;
  font-weight  : bold !important;
  margin-bottom: 8px;
}

.card-title p {
  color      : #808080;
  font-size  : 18px;
  line-height: 24px;
  font-family: system-ui !important;
}

.font-segoe {
  font-family: 'Segoe UI',
    sans-serif;
}

.MuiTypography-body2 {
  font-size: 16px !important;
  padding  : 1px
}

.MuiButton-outlinedError {
  border       : 1px solid #FF7D73 !important;
  color        : #FF7D73 !important;
  border-radius: 8px !important;
  font-size    : 12px !important;
}


h3 {
  font-size  : 30px !important;
  line-height: 46px !important;
  font-weight: bold !important;
}

.min-w-120 {
  min-width: 120px;
}

.h-30 {
  height: 30px;
}

.h-46 {
  height: 46px !important;
}

.text-16 {
  font-size: 16px !important;
}

.custom-card {
  border-radius: 12px !important;
  background   : #fff;
  box-shadow   : none !important;
  padding      : 25px !important;
}

::-webkit-scrollbar,
.MuiTableContainer-root::-webkit-scrollbar,
.ant-spin-container::-webkit-scrollbar {
  width : 3px;
  height: 3px;
}

::-webkit-scrollbar-track,
.MuiTableContainer-root::-webkit-scrollbar-track,
.ant-spin-container::-webkit-scrollbar-track {
  box-shadow   : inset 0 0 5px rgb(185, 185, 185);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb,
.MuiTableContainer-root::-webkit-scrollbar-thumb,
.ant-spin-container::-webkit-scrollbar-thumb {
  background   : rgb(185, 185, 185);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover,
.MuiTableContainer-root::-webkit-scrollbar-thumb:hover,
.ant-spin-container::-webkit-scrollbar-thumb:hover {
  background: #FF7D73;
}

.table-striped table tr th {
  background: transparent !important;
}

.table-striped table tr th,
.table-striped table tr td {
  padding: 10px !important;
}

.table-striped table tr:nth-child(odd) td {
  background: #f9f9f9;
}

.table-striped-header table tr:nth-child(odd) th {
  background: #f9f9f9 !important;
}

.table-striped-header table {
  width: 100%;
}

.table-striped-header th,
.table-striped-header td {
  width: 50%
}

.ant-table-row>.ant-table-cell-row-hover {
  background: unset !important
}

.MuiMenu-list li {
  font-weight: 400 !important;
}

.radius-8 {
  border-radius: 8px;
}

.InnerHeadingSec {
  margin-bottom: 20px;
}

.InnerHeadingSec h4 {
  font-size  : 20px;
  font-weight: bold;
}

.InnerHeadingSec p {
  font-size: 16px;
  color    : #808080;
}

.MuiTabs-flexContainer {
  margin-left: 50px !important
}

.no-margin .MuiTabs-flexContainer {
  margin: 0 !important;
}

.MuiTab-root {
  padding     : 0 !important;
  margin-right: 25px !important;
  min-width   : auto !important;
}

.breadcrumb {
  background   : #F8F8F8 !important;
  border-radius: 8px !important;
  font-size    : 14px !important;
  margin       : 0 !important;
  padding      : 10px 15px !important;
  height       : 40px !important
}

.breadcrumb>div:last-child img {
  display: none;
}

::-ms-input-placeholder {
  color      : #ABABAB;
  font-weight: 400;
}

::placeholder {
  color      : #ABABAB;
  font-weight: 400;
}

input {
  font-size: 16px !important;
  padding  : 8px 0 !important;
}

.layout-sec {
  margin     : 0;
  margin-top : 86px;
  margin-left: 345px;
  padding    : 40px !important;
  background : #F6F6F6;
  height     : 100%;
  height     : calc(100vh - 87px);
}

.layout-inner {
  padding      : 25px 35px;
  background   : #fff;
  border-radius: 12px;
  overflow     : auto;
  height       : calc(100vh - 130px);
}

.layout-inner::-webkit-scrollbar {
  width: 0px;
}

.dashboard-sec.layout-inner {
  background: transparent !important;
  padding   : 0
}

header {
  height         : 86px;
  background     : white !important;
  justify-content: center;
  padding        : 0 40px;
}

.radioBox {
  flex-basis    : 100%;
  display       : flex;
  flex-direction: row;
  gap           : 10px;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-10 {
  margin-top   : 10px !important;
  /* padding   : 0 40px; */
}

.btn-back {
  color         : gray !important;
  border-color  : gray !important;
  text-transform: capitalize !important;
}

.btn-back-default {
  border        : none !important;
  text-transform: capitalize !important;
  color         : #808080;
  letter-spacing: 0;
  padding       : 0px 30px 0px 10px;
}

.modal-submit-btn {
  min-width: 125px;
  font-size: 15px !important;
}

.thumbnail-profile {
  border-radius: 100px;
  object-fit   : cover;
}

.MuiModal-root>.MuiBox-root {
  border-radius: 12px;
}

.MuiTablePagination-toolbar p,
.base-TablePagination-root p {
  margin: 0
}

.MuiTablePagination-toolbar .MuiInputBase-root,
.base-TablePagination-root select {
  background   : #ff7d73 !important;
  color        : #fff !important;
  font-weight  : bold;
  border-radius: 6px;
  border       : none !important
}

.MuiTablePagination-toolbar .MuiInputBase-root svg {
  color: #fff;
}

.dashboard-card .MuiTypography-body1 {
  font-size  : 20px !important;
  line-height: 30px !important;
  font-weight: bold !important;
}

.dashboard-card .MuiTypography-subtitle1 {
  font-size  : 16px;
  line-height: 25px;
  color      : #808080;
}

.card-list {
  border-bottom: 1px solid #EFEFEF;
  padding      : 15px 0;
}

.card-list:last-child {
  border: none;
}

.card-list .MuiTypography-caption {
  font-size: 10px !important;
}

.card-list .category {
  color      : #398CDF;
  font-weight: 600;
}

.card-list .MuiTypography-body1 {
  font-size  : 16px;
  font-weight: 600;
  color      : #000;
}

.card-list .card-date {
  color: #808080;
}

.flex_row_center {
  display        : flex;
  align-items    : center;
  justify-content: center;
}

.flex_row_between {
  display        : flex;
  align-items    : center;
  justify-content: space-between;
}

a.navigateLink li>div {
  height : 61px;
  padding: 0 40px;
}

a.navigateLink li:before {
  content : '';
  width   : 7px;
  height  : 100%;
  position: absolute;
  left    : 0
}

a.navigateLink li.selected-dashboard:before {
  background   : #FF7D73;
  border-radius: 0px 7px 7px 0px;
  z-index      : 1;
}

.billing-center-inner-table .MuiTabs-flexContainer {
  margin: 0 !important;
}

.billing-center-inner-table .MuiTabs-root {
  padding: 0;
  margin : 0
}

.MuiTableFooter-root td {
  background: transparent !important;
}

.MuiTable-root {
  position     : relative;
  margin-bottom: 50px;
}

.MuiTableRow-footer .MuiBox-root {
  right     : 0;
  background: #fff;
  bottom    : -50px;
}

td.base-TablePagination-root {
  right          : 0;
  position       : absolute;
  display        : flex;
  justify-content: flex-end;
  bottom         : -50px;
}

.ticket-card {
  border-radius: 12px;
  height       : 116px;
}

.ticket-card img {
  width       : 40px;
  height      : 60px;
  margin-right: 20px;
}

.ticket-card p {
  font-size  : 16px;
  line-height: 1.125;
  font-weight: 500;
  color      : #2C2C2C;
}

.ticket-card h3 {
  font-size  : 40px !important;
  line-height: 60px !important;
  font-weight: bold !important;
}

.due-list {
  list-style: none;
  padding   : 0;
  margin    : 0;
  columns   : 2;
}

ul.due-list li {
  padding: 3px 0;
}

.rating-list {
  list-style: none;
  padding   : 0;
  margin    : 0;
}

ul.rating-list li {
  padding: 3px 0;
}

.radius-12 {
  border-radius: 12px;
}

.priority-list {
  columns   : 2;
  list-style: none;
  margin    : 0;
  padding   : 0;
}

.priority-list li {
  background   : #F6F6F6;
  border-radius: 10px;
  margin-bottom: 22px;
}

.priority-list .MuiTypography-body1 {
  font-size   : 16px !important;
  font-weight : 400 !important;
  display     : flex;
  height      : 64px;
  align-items : center;
  padding-left: 12px;
}

.priority-count {
  width          : 64px;
  height         : 64px;
  border-radius  : 10px;
  font-size      : 26px;
  line-height    : 39px;
  font-weight    : bold;
  display        : flex;
  align-items    : center;
  justify-content: center;
  color          : #fff;
}

.document-header {
  padding: 35px 45px 0 !important;
}

.h-48 {
  height: 48px;
}

.ticket-info-sec {
  padding-right: 20px;
}

.MuiInputBase-root.MuiInput-root::before {
  border-bottom: 1px solid #c7c7c7;
}

.block {
  display: block;
}

.pdfinfo {
  border       : 1px solid #FFA95D;
  border-radius: 8px !important;
  max-width    : 360px;
  height       : 62px;
  width        : 100%;
  margin-right : 10px;
}

.assets-detail-tab .MuiTabs-flexContainer {
  margin: 0 !important
}

.assets-detail-tab .MuiTabs-flexContainer .MuiButtonBase-root.MuiTab-root {
  color: #808080;
}