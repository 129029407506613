.proceedBtn{
    background-color: #FF7D73;
    color: white;
    padding: 5px 10px;
    border-radius: 8px;
border: none;outline: none;
}
.backBtn{
    border: none;outline: none;
    padding: 5px 10px;
    background-color: transparent;
    display: flex;
    align-content: center;
    align-items: center;justify-content: center;
    gap: 5px;
    cursor: pointer;
    
}
.box{
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-top: 30px;
}
.backImg{
    rotate: 180deg;
    object-fit: contain;
}