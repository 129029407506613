.MuiSwipeableDrawer-paper {
    background-color: "#222a45"!important;
}

.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
    background-color: #222a45 !important;
    color: white !important;
}
  .navigateLink{
    text-decoration: none; 
    color: inherit;
  }