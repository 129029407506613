.topBox {
}
.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.rentAmountBox,
.formItem,
.radioItem,
.dueDetails {
  display: flex;
  flex-direction: row;
  column-gap: 15px;
}
.rentAmountBox > *,
.formItem > *,
.dueDetails > * {
  flex-basis: 30%;
}

.radioBox {
  flex-basis: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
