.document-listing .col-md-3 {
    border-right: 2px solid #E9EBF2;
    padding     : 0
}

.document-listing ul,
.document-listing ol {
    padding : 0;
    margin  : 0;
    height  : calc(100vh - 250px);
    overflow: auto;
}

.document-listing ul li,
.document-listing ol li {
    list-style  : none;
    position    : relative;
    padding     : 5px 10px;
    text-indent : 12px;
    display     : flex;
    align-items : center;
    padding-left: 46px;
}

.document-listing li.active,
.document-listing li:hover {
    background: #F6F6F6;
    cursor    : pointer;
    color     : #FF7D73
}

.document-listing ul.level-one li:before {
    content : url(../../Assets/images/icon_assets.svg);
    width   : 18px;
    height  : 18px;
    position: relative;
    left    : -12px;
    opacity : 0.75;
}

.document-listing ul li:after,
.document-listing ol li:after {
    content : url(../../Assets/images/icon_arrow_right.svg);
    width   : 18px;
    height  : 18px;
    position: absolute;
    right   : 20px;
    opacity : 0.5;
}

.document-file img {
    box-shadow   : 0 3px 6px #00000029;
    margin-bottom: 80px;
    width        : 272px;
    height       : 385px;
    margin-top   : 50px;
    object-fit   : contain;
}

.document-file {
    display       : flex;
    flex-direction: column;
    align-items   : center;
}

.document-file table {
    margin-top: 40px
}

.document-file table th,
.document-file table td {
    width: 50%;
}