.loader-component {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
}

.spinner-loader {
    margin: 0 auto;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
}

.spinner-loader-common {
    padding-top: "50vh"
}