.smallImg {
  padding-right: 5px;
}

.topBox {
  background-color: #ffffff;
}

.editBtn {
  background-color: #ff7d73;
  border          : none;
  outline         : none;
  padding         : 0px 12px;
  color           : white;
  border-radius   : 10px;
}

.header {
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
  flex-wrap      : wrap;
}

.propertySection {
  display       : flex;
  flex-direction: row;
  gap           : 10px;
}

.mainImg {
  width        : 150px;
  height       : 150px;
  border-radius: 20;
  object-fit   : cover;
}

.galleryIMg {
  display       : flex;
  flex-direction: row;
  gap           : 12px;
}

.detailsBox>div {
  display       : flex;
  flex-direction: row;
}

.detailsBox>div>p {
  flex-basis: 40%;
}