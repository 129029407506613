.radioButton {
    display       : flex;
    flex-direction: row;
    gap           : 5px;
}

.lable {
    font-size: 13px;
}

input[type="radio"] {
    -webkit-appearance: none;
    appearance        : none;
    background-color  : var(--form-background);
    margin            : 0;
    font              : inherit;
    width             : 17px;
    height            : 17px;
    border            : 1px solid #B8B8B8;
    border-radius     : 50px;
    display           : grid;
    place-content     : center;
    padding           : 0 !important
}

input[type="radio"]::before {
    content         : "";
    width           : 11px;
    height          : 11px;
    border-radius   : 50%;
    transform       : scale(0);
    transition      : 120ms transform ease-in-out;
    box-shadow      : inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: #FF7D73;
}

input[type="radio"]:checked::before {
    transform: scale(1);
}