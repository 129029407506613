/* LoginStyle.css */

.unauthorized-layout {
    height: 100vh !important;
    text-align: -webkit-center;
    padding-top: 50vh;
}

.login-container {
    background-color: #f8f9fa;
    border: 1px solid #ced4da;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
    height: auto;
}