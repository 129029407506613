*{
    /* border: 0.1px solid red; */
}
.topBox{

}
/* .form{
    display: flex;
    flex-direction: row;
justify-content: space-between;
flex-wrap: wrap;
} */
.inputTotalBox{
    display: flex;
    flex-direction: row;
    column-gap: 50px;
/* flex-basis: 30%; */
    flex-wrap: wrap;
    /* justify-content: space-between; */
    /* justify-content: space-between; */
}
.inputTotalBox  *{
flex-basis: 30%;
}

.radioBox{
    flex-basis: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.btn{
    flex-basis: 100%;
}