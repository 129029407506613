/* TextField.module.css */
.textField {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 1rem; */
  /* border-bottom: 1px solid #ccc; */
  padding: 10px 0px 5px;
}

/* .textField.error {
    border-color: red;
  } */

.label {
  margin-bottom: 5px;
}

/* .errorMessage {
    color: red;
    font-size: 0.8rem;
    margin-top: 5px;
  } */
.input {
  border: none;
  outline: none;
  border-bottom: 1px solid #c7c7c7;
}
.input:focus {
  /* outline: none; */
  border: none;
  border-bottom: 1px solid #ff7d73;
}

/* .input[type="file"]::file-selector-button::before {
    content: 'Choose Fileqqqqqqq'; 
  } */

/* .input[type="file"] {
  cursor: pointer;
  color: transparent;
}

.input[type="number"]::-webkit-inner-spin-button,
.input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.input[type="number"] {
  -moz-appearance: textfield; 
} */
