.box {
  display        : flex;
  justify-content: space-between;
  align-items    : center;
  /* Optional    : centers content vertically */
  margin         : 10px 0px;
}

.left {
  display    : flex;
  align-items: center;
}

.heading {
  color      : #2C2C2C;
  font-size  : 24px;
  line-height: 32px;
  font-weight: bold;
}

.subheading {
  color      : #808080;
  font-size  : 18px;
  line-height: 24px;
}

.leftText {
  margin: 0px 5px;
  cursor: pointer;
  /* Add your desired styles for left text here */
}

.arrow,
.leftText {
  display: inline;
}