.box {
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
  /* width:100%; */
}
.datePickBox * {
  width: 100%;
  border: none;
  display: flex;
  justify-content: space-between;
}




